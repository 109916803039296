body {
  margin: 0;
  font-family: 'aktiv-grotesk', sans-serif; /* Add this line */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('../public/fonts/AktivGrotesk/OTF/AktivGrotesk-Regular.otf') format('opentype');
}
